import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatProgressService {
  private chatProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private selectedChatType: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor() {
  }

  getChatProgressState(): BehaviorSubject<number> {
    return this.chatProgress;
  }

  setChatProgressState(progress: number): void {
    this.chatProgress.next(progress);
  }

  getSelectedChatType(): BehaviorSubject<string> {
    return this.selectedChatType;
  }

  setSelectedChatType(selectedChatType: string): void {
    this.selectedChatType.next(selectedChatType);
  }
}
