import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { CodHandlingFeeSheetComponent } from './cod-handling-fee-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
  ],
  declarations: [CodHandlingFeeSheetComponent],
  exports: [CodHandlingFeeSheetComponent],
})
export class CodHandlingFeeSheetModule { }
