import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { AppConfig } from 'client/app/app.config';

type LanguageDetails = { code: string, displayName: string };

@Component({
  selector: 'language-change',
  templateUrl: './language-change.html',
  styleUrls: [],
})
export class LanguageChangeComponent implements OnInit {
  saveInProcess: boolean = false;
  languages: LanguageDetails[] = [];
  userCurrentLanguageCode: string = this.appConfig.Shared.Languages.EN;
  selectedLanguage: LanguageDetails = {
    code: this.appConfig.Shared.Languages.EN,
    displayName: this.appConfig.Shared.LanguagesLong.EN,
  };
  useDefaultNavigate: boolean = true;

  /*
    * The constructor injects the data passed to the bottom sheet using Angular's dependency injection.
    * The 'new-cap' linting rule is disabled for this line because it doesn't apply in this case,
    * as the uppercase letter is part of the Angular-specific token MAT_BOTTOM_SHEET_DATA.
  */
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data:
    { useDefaultNavigate?: boolean, callback?: (parameter: LanguageDetails) => Promise<any> },
    public appConfig: AppConfig,
    private conn: ConnectionService,
    private windowRef: WindowRefService,
    private bottomSheetRef: MatBottomSheetRef<LanguageChangeComponent>) { }

  ngOnInit(): void {
    this.useDefaultNavigate = this.data?.useDefaultNavigate ?? true;
    this.setLanguageDetails();
  }

  setLanguageDetails(): void {
    try {
      this.languages = [
        { code: this.appConfig.Shared.Languages.HI, displayName: this.appConfig.Shared.LanguagesLong.HI },
        { code: this.appConfig.Shared.Languages.EN, displayName: this.appConfig.Shared.LanguagesLong.EN },
        { code: this.appConfig.Shared.Languages.KN, displayName: this.appConfig.Shared.LanguagesLong.KN },
        { code: this.appConfig.Shared.Languages.TE, displayName: this.appConfig.Shared.LanguagesLong.TE },
        { code: this.appConfig.Shared.Languages.TA, displayName: this.appConfig.Shared.LanguagesLong.TA },
        // { code: this.appConfig.Shared.Languages.BN, displayName: this.appConfig.Shared.LanguagesLong.BN },
        // { code: this.appConfig.Shared.Languages.ML, displayName: this.appConfig.Shared.LanguagesLong.ML },
        { code: this.appConfig.Shared.Languages.MR, displayName: this.appConfig.Shared.LanguagesLong.MR },
      ];

      const user = this.conn.getActingUser();
      const userSelectedLangCode = user.get('languagePreference');
      this.userCurrentLanguageCode = userSelectedLangCode || this.appConfig.Shared.Languages.EN;
      this.selectedLanguage = {
        code: userSelectedLangCode || this.appConfig.Shared.Languages.EN,
        displayName: this.appConfig.Shared.LanguagesLong[userSelectedLangCode?.toUpperCase()
          || this.appConfig.Shared.Languages.EN.toUpperCase()],
      };
    } catch (err) {
      // handle error
    }
  }

  handleLanguageChange(language: LanguageDetails): void {
    if (this.saveInProcess) {
      return;
    }
    this.selectedLanguage = language;
  }

  closeBottomSheet(): void {
    if (this.saveInProcess) {
      return;
    }
    this.bottomSheetRef.dismiss();
  }

  updateSaveInProgress(value: boolean): void {
    this.saveInProcess = value;
    this.bottomSheetRef.disableClose = value;
  }

  async handleConfirmClick(): Promise<void> {
    try {
      if (this.selectedLanguage.code === this.userCurrentLanguageCode) {
        this.closeBottomSheet();
        return;
      }

      this.updateSaveInProgress(true);

      const user = this.conn.getActingUser();
      if (user) {
        await this.conn.updateUserData({ languagePreference: this.selectedLanguage.code });
      }

      if (this.data?.callback) {
        await this.data.callback(this.selectedLanguage);
      }

      if (this.useDefaultNavigate) {
        const domain = this.conn.getBaseUrl();
        let path = this.windowRef.nativeWindow.location.pathname;
        const queryParams = this.windowRef.nativeWindow.location.search;

        const currentLanguageCode = this.userCurrentLanguageCode;
        const newLanguageCode = this.selectedLanguage.code;
        const languagePathSegment = `/${currentLanguageCode}`;

        if (currentLanguageCode === this.appConfig.Shared.Languages.EN || !path.startsWith(languagePathSegment)) {
          path = `/${newLanguageCode}${path}`;
        } else {
          const replacement = newLanguageCode === this.appConfig.Shared.Languages.EN
            ? ''
            : `/${newLanguageCode}`;

          path = path.replace(languagePathSegment, replacement);
        }

        const newUrl = `${domain}${path}${queryParams}`;
        this.windowRef.nativeWindow.location.href = newUrl;
      }

      this.updateSaveInProgress(false);
      this.closeBottomSheet();
    } catch (err) {
      this.updateSaveInProgress(false);
      this.closeBottomSheet();
    }
  }
}
