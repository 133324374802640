import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { LanguageChangeComponent } from './language-change.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
  ],
  declarations: [LanguageChangeComponent],
  exports: [LanguageChangeComponent],
})
export class LanguageChangeModule { }
