<div class="tw-bg-white font-noto tw-rounded-full">
  <div class="tw-flex tw-items-center tw-justify-end tw-px-2 tw-pt-4 tw-pb-0">
    <div class="tw-flex tw-justify-center tw-items-center tw-p-1 tw-rounded-full tw-bg-gray-100 tw-z-[100] tw-top-[15px] tw-right-[10px] tw-w-[35px] tw-h-[35px]" (click)="closePopup()">
      <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
    </div>
  </div>
  <div class="tw-pl-4 tw-pr-4 tw-pb-7 tw-mt-[-6px]">
    <ng-container *ngIf="showFrontFaceContent; then frontFaceContentTemplate else sideFaceContentTemplate"></ng-container>
    <ng-template #frontFaceContentTemplate>
      <h2 class="tw-max-w-[calc(100vw-64px)] tw-text-lg tw-font-semibold tw-text-700 tw-mb-2" i18n="@@frontFaceNotDetected">Front face photo not detected!</h2>
      <p class="tw-my-2 tw-text-400 tw-text-gray-500" i18n="@@frontFaceNotDetectedContent"> Please look straight into the camera to help us correctly detect your front face photo. The current photo will be saved as a side profile. </p>
    </ng-template>
    <ng-template #sideFaceContentTemplate>
      <h2 class="tw-max-w-[calc(100vw-64px)] tw-text-lg tw-font-semibold tw-text-700 tw-mb-2" i18n="@@sideFaceNotDetected">Side face photo not detected!</h2>
      <p class="tw-my-2 tw-text-400 tw-text-gray-500" i18n="@@sideFaceNotDetectedContent">Please turn your face to the left / right to help us correctly detect your side photo. The current photo will be saved in front profile.</p>
    </ng-template>
    <cs-button class="tw-mt-6" [class]="'w100 primary'" (callback)="handleRetakePhoto()">
      <cs-button-text i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
    </cs-button>
  </div>
</div>
