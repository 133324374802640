import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '@directives/directives.module';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { PopUpModule } from '@components/pop-up';
import { ButtonModule } from '@components/button';
import { InputModule } from '@components/inputs';
import { CsIconModule } from '@components/icon';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CCODBottomSheetModule } from '@shared/bottom-sheet-layouts/ccod-bottom-sheet/ccod-bottom-sheet.module';
import { DiscountTimerModule } from '@components/discount-timer/discount-timer.module';
import { PrepaidDiscountSheetModule } from '@shared/bottom-sheet-layouts/prepaid-discount/prepaid-discount-sheet.module';
import { CodHandlingFeeSheetModule } from '@shared/bottom-sheet-layouts/cod-handling-fee-sheet/cod-handling-fee-sheet.module';
import { OrderSummaryPopupComponent } from './order-summary-popup/order-summary-popup.component';
import { UserOrderPaymentComponent } from './user-order-payment.component';

export function playerFactory(): LottiePlayer {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
    RouterModule,
    ButtonModule,
    DirectivesModule,
    DiscountTimerModule,
    FormsModule,
    LoadingModule,
    ToolbarModule,
    PopUpModule,
    InputModule,
    CCODBottomSheetModule,
    MatBottomSheetModule,
    PrepaidDiscountSheetModule,
    CodHandlingFeeSheetModule,
    [LottieModule.forRoot({ player: playerFactory })],
  ],
  declarations: [
    OrderSummaryPopupComponent,
    UserOrderPaymentComponent,
  ],
  exports: [
    UserOrderPaymentComponent,
  ],
})
export class UserOrderPaymentModule {}
