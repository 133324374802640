import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  private codHandlingFeePopupShown: boolean = false;
  constructor() {
  }

  getCodHandlingFeePopupState(): boolean {
    return this.codHandlingFeePopupShown;
  }

  setcodHandlingFeePopupShown(): void {
    this.codHandlingFeePopupShown = true;
  }
}
