<div class="font-noto tw-relative tw-px-7 tw-pt-14 tw-pb-[24px]">
  <div class="tw-absolute tw-p-1 tw-z-[100] tw-top-[16px] tw-right-[16px] tw-flex tw-justify-center close-sheet" style="width: 24px; height: 24px" (click)="closeBottomSheet()">
    <div class="tw-text-black tw-text-600 tw-font-bold">&#10005;</div>
  </div>
  <p class="tw-w-[70%] tw-mx-auto tw-text-black tw-font-bold tw-text-center tw-text-700" i18n="@@feeForCodOrders"> ₹{{data?.charges}} Handling Fee for COD orders. </p>
  <p class="tw-w-[70%] tw-mx-auto tw-mt-2.5 tw-text-center tw-text-400 tw-text-gray-700" i18n="@@payOnlineToAvoidFee">You can pay online to avoid additional handling fee</p>
  <cs-button class="tw-mt-7" id="continueButton" (click)="handleOnlineOptionClick()" [class]="'primary w100'" [loading]="loading">
    <cs-button-text class="tw-leading-[0]">
      <span [ngClass]="data.userLanguage === appConfig.Shared.Languages.TA || data.userLanguage === appConfig.Shared.Languages.TE ? 'tw-text-300' : 'tw-text-400'" i18n="@@payOnlineHandlingFee">Pay ₹{{ data?.orderSP - data?.charges }} Online</span>
    </cs-button-text>
  </cs-button>
  <cs-button class="tw-mt-3 tw-border-black tw-border-solid" id="continueButton" (click)="handleCODOptionClick()" [class]="'secondary w100'" [loading]="loading">
    <cs-button-text class="tw-font-body tw-leading-[0]">
      <div class="tw-flex tw-justify-center tw-flex-wrap">
        <div> <span class="tw-font-normal" [ngClass]="data.userLanguage === appConfig.Shared.Languages.TA || data.userLanguage === appConfig.Shared.Languages.TE ? 'tw-text-300' : 'tw-text-400'" i18n="@@prepaidCashOnDelivery">Cash on delivery</span> - </div>
        &nbsp; <span class="tw-text-green-500" [ngClass]="data.userLanguage === appConfig.Shared.Languages.TA || data.userLanguage === appConfig.Shared.Languages.TE ? 'tw-text-300' : 'tw-text-400'"> <span i18n="@@codHandlingPay"> Pay ₹{{ data?.orderSP }} </span> </span>
      </div>
    </cs-button-text>
  </cs-button>
</div>
